<template>
  <div
    id="groups-listing"
    class="p-2"
  >
    <div class="d-flex align-items-center card-wrapper">
      <div class="d-flex w-50 breadcrumb-wrapper justify-content-start">
        <BreadCrumb :bread-crumb-items="breadCrumbItems" />
      </div>
      <div class="d-flex w-50 font-weight-bold button-style justify-content-end button-wrapper">
        <span
          class="text-gray mr-1 d-flex justify-content-end"
        >
          <b-button
            variant="primary"
            class="buttons"
            @click="createNewGroup()"
          >
            Create Business
          </b-button>
        </span>
      </div>
    </div>
    <b-row>
      <b-col
        sm="6"
        md="6"
        lg="6"
      >
        <div class="mt-2 ml-1">
          <h4 class="my-groups">
            My Businesses
          </h4>
          <p> {{ getGroups.length }} {{ getGroups.length === 1 ? 'Result' : 'Results' }}  </p>
        </div>
      </b-col>
    </b-row>
    <!-- pricing plan cards -->
    <b-row class="pricing-card">
      <b-col
        v-for="(group) in getGroups"
        :key="group.name"
        sm="6"
        md="6"
        lg="6"
      >
        <b-card
          align="center"
          class="m-1 cursor-pointer card-border"
          @click="setSelectedGroupId(group ? group : '')"
        >
          <div class="d-flex align-items-center">
            <div class="d-flex justify-content-start w-50">
              <!-- img -->
              <div>
                <b-avatar
                  :src="(group.user_has_group && group.user_has_group.image) ? group.user_has_group.image : require('@/assets/images/avatars/Group-place-holder.svg')"
                  size="40"
                  alt="group img"
                />
                <span
                  class="ml-1 align-items-center font-weight-bolder"
                >
                  {{ (group.user_has_group && group.user_has_group.name) ? group.user_has_group.name: '' }}
                </span>
                <b-badge
                  v-if="getUserOwnCreatedGroupIds.includes(group.user_has_group.id) "
                  pill
                  variant="success"
                  class="badge-glow text-nowrap font-small-3 group-listing-pill"
                >
                  Your Businesses
                </b-badge>
              </div>

            </div>
            <div class="w-50 d-flex justify-content-end">
              <div>
                <!-- avatar group -->
                <b-avatar-group
                  v-if="group.user_has_group && group.user_has_group.user_group_has_members && group.user_has_group.user_group_has_members.length"
                  size="28"
                >
                  <b-avatar
                    v-for="member in group.user_has_group.user_group_has_members.slice(0, 5)"
                    :key="(member.user && member.user.id) ? member.user.id : member.id"
                    v-b-tooltip.hover.bottom="(member.user && member.user.name) ? member.user.name : member.user.email"
                    :src="(member.user && member.user.image) ? member.user.image : require('@/assets/images/avatars/User-place-holder.svg')"
                    class="pull-up"
                  />
                  <p
                    v-if="group.user_has_group.user_group_has_members.length > 5"
                    class="align-self-center fw-500 cursor-pointer ml-1 mb-0"
                  >
                    .&nbsp;.&nbsp;.
                  </p>
                  <p class="align-self-center cursor-pointer ml-1 mb-0">
                    {{ textFunc(group.user_has_group.user_group_has_members) }}
                  </p>
                </b-avatar-group>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
  <!--/ pricing plan cards -->
</template>

<script>
import {
  BRow, BCol, BCard, BAvatar, BButton, VBTooltip, BAvatarGroup, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import BreadCrumb from '@/components/common/BreadCrumb.vue'
import MemberType from '@/common/enums/memberTypeEnum'
import { showToast } from '@/common/global/functions'

/* eslint-disable global-require */
export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BAvatar,
    BAvatarGroup,
    BBadge,
    BreadCrumb,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  async beforeRouteEnter(to, from, next) {
    const userGroups = await store.getters['user/getGroups']
    const isUserAdmin = await store.getters['user/isUserAdmin']
    if (isUserAdmin) {
      next({
        path: '/admin/applications',
      })
      return
    }
    if (!isUserAdmin && userGroups.length <= 0) {
      next({
        name: 'pricing-plans',
      })
      return
    }
    next()
  },
  data() {
    return {
      MemberType,
      status: 'monthly',
      monthlyPlanShow: true,
      options: [5, 10, 25, 50, 75],
      breadCrumbItems: [
        { name: 'Home', to: '/', active: false },
        { name: 'Businesses', to: null, active: true },
      ],
    }
  },
  computed: {
    getGroups() {
      return this.$store.getters['user/getGroups']
    },
    getUserOwnCreatedGroup() {
      return this.$store.getters['user/getUserOwnCreatedGroup']
    },
    getUserOwnCreatedGroupIds() {
      return this.$store.getters['user/getUserOwnCreatedGroupIds']
    },
    getMemberRoleInSelectedGroup() {
      return this.getSelectedGroupMember ? this.getSelectedGroupMember.role : null
    },
  },
  async mounted() {
    await this.$store.dispatch('user/setAllUserGroupIfNotPresent')
  },
  methods: {
    textFunc(members) {
      if (members.length === 1) {
        return '1 Member'
      }
      return `${members.length} Members`
    },
    async setSelectedGroupId(group) {
      if (group && group.user_has_group && group.user_has_group.package && group.user_has_group.package.isActive) {
        await this.$router.push({ path: '/applications', query: { groupId: `${group.user_has_group.id}` } })
      } else if (group && group.user_has_group && group.user_has_group.id && group.role === MemberType.OWNER) {
        await this.$router.push({ path: '/applications', query: { groupId: `${group.user_has_group.id}` } })
      } else {
        showToast('Business Subscription', 'Business subscription is expired!', 'danger', 4000)
      }
    },
    createNewGroup() {
      this.$router.push({ path: '/pricing-plans' })
    },
    ucFirst(val) {
      return val.replace(/(^|\s)[A-Za-zÀ-ÖØ-öø-ÿ]/g, c => c.toUpperCase())
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-pricing.scss';
#groups-listing{
  .card{
    min-width: 280px !important;
    border-radius: 12px !important;
  }
  .card-body{
    padding: 1rem !important;
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .my-groups{
    font-weight: 700;
    color: black;
  }
  .group-listing-pill{
    margin-left: 8px;
  }
}
@media (max-width: 560px) {
  .card-wrapper{
    flex-direction: column;
  }
  .breadcrumb-wrapper{
    width: 100% !important;
  }
  .button-wrapper{
    width: 100% !important;
  }
}
</style>
